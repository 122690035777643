import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Heading, Text, Flex, AspectRatio, Button } from '@chakra-ui/react';
import { Link } from 'gatsby';
import Proyectos from '../../components/proyectos';
import { VideoSchema } from '../../helpers/schemaGenerator';
import ContactBanner from '../../components/contactBanner';
import { StaticImage } from 'gatsby-plugin-image';
import ogImg from '../../../content/images/servicios/Museos-Interactivos-en-Mexico3Exp3.jpg';
import { ChevronRightIcon } from '@chakra-ui/icons';

const Museos = ({ lang='es' }) => {
  const l = (...texts) => lang === 'en' ? texts[1] : texts[0]
  const pageTitle = l(' 🖌️ Instalaciones interactivas y proyecciones para museos', ' 🖌️ Interactive installations and video projections for museums')
  const pageImage =
    'https://visualma.com' + ogImg
  const pageDescription =
  l('Creamos experiencias atractivas y didácticas con instalaciones interactivas, proyecciones avanzadas, apps y realidad virtual para museos en México',
    'We create atractive and didactive experiences with interactive installations, advanced projection, sensors, and virtual reality for all kind of museums.')
  const pageUrl = l('https://visualma.com/museos-instalaciones', 'https://visualma.com/en/museum-interactive-art')

  return (
    <Layout lang={lang}>
      <Head lang={lang}
        pageTitle={pageTitle}
        imageUrl={pageImage}
        pageDescription={pageDescription}
      />
      <VideoSchema
        videoUrl="https://www.youtube.com/embed/tHnKK6L6rK8"
        contentUrl={pageUrl}
        imageUrl={pageImage}
        title={pageTitle}
        description={
          l('Ejemplos de Museos e instalaciones interactivas en México creados por la agencia Visualma',
          'Examples of interactive installations for museums created by Visualma')
        }
        date="2017-03-22T00:49:57Z"
        duration="PT3M1S"
      />

      <div>
        {/* ----- HeroImage ----- */}
        <Flex justifyContent="flex-end">
          <h2 className="pageHeader">
            {l(<><b>Museos interactivos</b> en México</>,
              <><b>Interactive Museums</b></>)}
          </h2>
        </Flex>

        <StaticImage
          className="headerImg"
          layout="fullWidth"
          formats={['auto', 'webp', 'avif']}
          loading="eager"
          src="../../../content/images/servicios/Museos-Interactivos-en-Mexico3Exp3.jpg"
          alt={l('Museos e instalaciones en México', 'Museums and interactive installations')}
        />

        <Flex className="headerTitleWrapper">
          <div>
            <h1 className="parrafo parrafoGrande headerTitle">
              {l(<>Desarrollamos{' '}<b>Instalaciones interactivas</b> y{' '}<b>tecnología</b> para museos innovadores</>,
                <>We create{' '}<b>Interactive installations</b> and {' '}<b>technology</b> for innovative museums</>)}
            </h1>
          </div>
        </Flex>
        {/* ----------------------- */}
        
        <p className="parrafo">
          {l('Ofrecemos las tecnologías y experiencias que respaldan las salas de los mejores museos del mundo.',
            'We offer the technologies and experiences that back up the exhibitions of the best museums in the world.')}
        </p>
        <div className="contenidoSmall">
          <AspectRatio
            maxWidth="65em"
            style={{ margin: '0 auto' }}
            ratio={16 / 9}
          >
            <Box
              as="iframe"
              loading="lazy"
              title="Instalaciones interactivas para museos y exposiciones"
              src="https://www.youtube.com/embed/OOtS7GvM8Tk?vq=hd1080&modestbranding=1&color=red&iv_load_policy=3&rel=0"
              allowFullScreen
              borderRadius={[0, 0, 0, '8px']}
            />
          </AspectRatio>
        </div>

        <p className="parrafo" style={{marginTop:'1.5em'}}>
          {l(<>
            Video proyección, interactividad y aplicaciones que <b style={{ color: 'var(--accent-color)' }}>generan presencia en medios y atraen nuevos publicos.</b>
          </>, 
          <>
            Advanced video projections, interactivity and applications that <b style={{ color: 'var(--accent-color)' }}>generate a presence in the media and attract new public</b>.
          </>)}
        </p>
        <div className="contenidoSmall">
          <Box
            style={{
              margin: '0em auto 2em',
              width: '100%',
              minHeight: '260px',
            }}
            as="iframe"
            loading="lazy"
            title="Realidad virtual y realidad aumentada para museos y exposiciones"
            src="https://www.youtube.com/embed/hhFQ78wSl3g?vq=hd1080&modestbranding=1&color=red&iv_load_policy=3&rel=0"
            allowFullScreen
            borderRadius={[0, 0, 0, '8px']}
          />
        </div>
        <p className="parrafo">
          {l(<>Utilizamos nuestra experiencia museográfica para potenciar con tecnología de punta la temática y objetivos de cada sala, creando <b>experiencias atractivas, accesibles y didácticas</b>.</>,
            <>We use our museography experience to amplify the theme and objectives of every exhibit with technology solutions that create <b>attractive, didactive and accessible experiences.</b>.</>)}
        </p>

        <Flex className="flexList">
          <Flex className="columna">
            <Heading as="h3">{l('Proyecciones inmersivas', 'Inmersive video projections')}</Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Instalaciones que rodean por completo a los visitantes para transportarlos a eventos históricos, ambientes naturales o grandes obras de arte.',
              'Installations that completely surround the visitors and transports them to historic events, natural sceneries and great works of art.')}
            </Text>
          </Flex>
          <Flex className="columna">
            <Heading as="h3">{l('Instalaciones interactivas', 'Interactive installations')}</Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Hacemos de los visitantes participantes activos del museo, con experiencias divertidas y memorables que invitan a profundizar en la temática de la sala.',
                'We make the public an active participant of the museum with fun and memorable experiences that invite them to learn more.')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3">{l('Aplicaciones educativas', 'Educational applications')}</Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Creamos herramientas didácticas que ayudan a los visitantes de los museos a aprender y explorar conceptos complejos a su propio paso.',
              'Didactic tools that help the museums visitors learn and explore complex concepts at their own rythm.')}
            </Text>
          </Flex>
        </Flex>

        <div className="contenidoSmall">
          <Box
            style={{
              margin: '0em auto 2em',
              width: '100%',
              minHeight: '260px',
            }}
            as="iframe"
            loading="lazy"
            title="Video proyecciones espectaculares para museos"
            src="https://www.youtube.com/embed/AcoaCVIaTSE?vq=hd1080&modestbranding=1&color=red&iv_load_policy=3&rel=0"
            allowFullScreen
            borderRadius={[0, 0, 0, '8px']}
          />
        </div>

        <p className="parrafo">
          {l(<>
              <b style={{ color: 'var(--accent-color)' }}>Renovamos salas existentes</b>{' '}
              con contenidos modernos y hardware duradero para dar nueva vida a
              la inversión original.
            </>,
            <>
              We <b style={{ color: 'var(--accent-color)' }}>renovate existing exhibitions with modern content</b>{' '} , software and hardware to give life to the original inversión.
            </>)}
        </p>
        <div className="contenidoSmall">
          <Box
            style={{
              margin: '0em auto 2em',
              width: '100%',
              minHeight: '260px',
            }}
            as="iframe"
            loading="lazy"
            title="Video proyecciones espectaculares para museos"
            src="https://www.youtube.com/embed/tHnKK6L6rK8?vq=hd1080&modestbranding=1&color=red&iv_load_policy=3&rel=0"
            allowFullScreen
            borderRadius={[0, 0, 0, '8px']}
          />
        </div>
        <p className="parrafo">
          {l('La mayoría de los museos presentan oportunidades de renovación y mejora sobre su infraestructura existente con una inversión mínima. Agregar contenidos actuales invita al público a revivir el museo como una nueva experiencia.',
          'Most museums present oportunities to renovate and make their infrastructure better with a minimal investment. Adding new content invites the public to re-live the experience.')}
        </p>

        <br />
        <br />

        <Heading as="h3" className="subHeader" marginBottom="0em">
          {l('Tecnologías ideales para cada experiencia', 'The ideal technology for every experience')}
        </Heading>
        <Flex className="flexList" borderTop="none" marginTop="0">
          <Flex className="columna">
            <Heading as="h3">
              {l('Pantallas Touch y Apps para Museo', 'Touch screens & museoum apps')}
              
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Aplicaciones funcionales y modernas que complementan y enriquecen la visita.',
              'Functional and modern applications that complement and enhance the visit.',)}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3">
              {l('Proyecciones 360º y Domos y Planetarios', '360º video projections & domes')}
              <br /> 
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Contenidos impactantes para cualquier geometría y configuración de proyección.',
                'Amazing content for every geometry and room configuration.')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3">
              {l('Guías virtuales y Realidad Aumentada', 'Virtual tours & augmented reality')}
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Unimos el mundo real con contenido digital para ofrecer información contextual y dar vida a las salas.',
                'We augment the real world with digital content to offer contextual information and give life to rooms and exhibitions.')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3">
              <Link to="/realidad-virtual">Realidad Virtual</Link>
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('El hardware más moderno para que el visitante viva de primera mano la temática de la sala e interactúe con ella.',
                'We use the best software and hardware to let the visitor live and interact with every room and exhibit.')}

              <Button
                as={Link}
                to={l('/realidad-aumentada', '/en/augmented-reality')}
                colorScheme="gray"
                variant="outline"
                p="1em"
                size="sm"
                m="0 auto"
                style={{ color: '#0d1d23', marginTop: '14px' }}
                rightIcon={<ChevronRightIcon />}
              >
                {l('Ver servicios de Realidad Aumentada', 'View Augmented Reality services')}
              </Button>
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3">{l('Hologramas', 'Holograms')}</Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Visualizaciones futuristas que dan vida a personajes históricos, valiosos artefactos y presentadores virtuales.',
                'Futuristic visualizations that give life to historical characters, 3D art pieces and virtual hosts.')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3">
              <Link to="/video-mapping">{l('Mapping 3D', '3D Projection Mapping')}</Link>
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Experiencias colectivas enfocadas en el máximo impacto y memorabilidad a partir de poderosas ilusiones visuales.',
                'Collective experiences focused on being memorable and having the maximum impact with the use of powerful visual illusions.')}
            </Text>
          </Flex>
        </Flex>

        <ContactBanner lang={lang} />

        <Proyectos lang={lang}
          title={l('Instalaciones para museos', 'Museum installation examples')}
          category="Museos"
          limit={3}
        />
      </div>
    </Layout>
  );
};
Museos.propTypes = {
  lang: PropTypes.string,
};

export default Museos;
